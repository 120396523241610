/* Sidebar.css */
.espacamento-navbar{
  height: 2%;
}
/* Sidebar.css */
.sidebar {
  width: 18vw;
  height: 91vh;
  background-color: #00284D;
  display: flex;
  flex-direction: column;
  padding: 40px 20px 20px 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.logo-sidebar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.logo-sidebar {
  cursor: pointer;
  width: 60%;
  height: auto;
}
.divider-sidebar {
    width: 100%; /* Ocupa toda a largura disponível */
    height: 1px; /* Espessura do divisor */
    background-color: #bcbcbc; /* Cor do divisor */
    margin: 20px 0; /* Espaçamento acima e abaixo do divisor */
  }

.menu-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0067FF;
  color: #fff;
  padding: 16px;
  margin-bottom: 20px;
  border-radius: 15px; /* Borda arredondada */
  border: none; /* Sem borda */
  position: relative;
  width: 100%; /* Ocupar a largura máxima */
  height: clamp(2rem, 10vw, 6rem); /* Altura responsiva */	
}

.menu-button.active {
  background-color: white;
  color: #0067FF;
  width: 100%; /* Preencher a área restante quando ativo */
}

.menu-button:hover{
  background-color: #2a43bf;
}
.menu-button.active:hover{
  background-color: rgb(243, 243, 243);
}

/* Efeito hover apenas no ícone e círculo */
.menu-button:hover .menu-icon,
.menu-button.active:hover .menu-icon {
    color: white; /* Ícone branco ao passar o mouse */
}

.menu-button:hover .circle,
.menu-button.active:hover .circle {
    background-color: #3751d7; /* Círculo azul ao passar o mouse */
}
/* Add a rectangle to the right side of the menu button */
.menu-button.active::after {
  content: '';
  position: absolute;
  top: 0;
  right: -20px; /* Position outside the button */
  width: 16%;  /* Width of the rectangle */
  height: 100%; /* Full height of the button */
  background-color: white;
  z-index: 0;
}
.menu-button.active:hover::after {
  content: '';
  position: absolute;
  top: 0;
  right: -20px; /* Position outside the button */
  width: 16%;  /* Width of the rectangle */
  height: 100%; /* Full height of the button */
  background-color: rgb(243, 243, 243);
  z-index: 0;
  transition: none;

}

.menu-button-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 85%;
}

.icon-circle {
  position: relative;
  margin: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.menu-icon {
  font-size: 30px;
  color: #0067FF;
  position: relative;
  z-index: 1; /* Colocar o ícone à frente do círculo */
}

.circle {
  position: absolute;
  width: 220%;  /* Tamanho maior que o ícone */
  height: 220%;
  border-radius: 50%;
  background-color: white;
  z-index: 0; /* Colocar o círculo atrás do ícone */
}

/* Quando o botão está ativo, o círculo fica azul e o ícone branco */
.menu-button.active .circle {
  background-color: #0067FF; /* Círculo azul */
}

.menu-button.active .menu-icon {
  color: white; /* Ícone branco */
}

.sidebar-text {
  margin-left: 8%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left; /* Alinhamento à esquerda */
  width: 100%;
  
}

.sidebar-text h3 {
  margin: 0;
  font-size: clamp(0.3rem, 0.9rem, 1.3rem); /* Título maior */
  color: white;
}

.sidebar-text p {
  margin: 5px 0 0;
  font-size: clamp(0.2rem, 0.7rem, 1rem); /* Descrição menor */
  color: white;
  text-align: left; /* Alinhamento à esquerda para a descrição */
}

.menu-button.active .sidebar-text h3,
.menu-button.active .sidebar-text p {
  color: #0067FF; 
}



@media (max-width: 720px) {
  .sidebar {
    padding: 0;
    width: 100vw;
    overflow-x: hidden;
    flex-direction: row;
    height: 6vh;
    background-color: #F7F8FA;
    justify-content: space-evenly;
  }

  .menu-button {
    background-color: transparent;
    width: max-content;
    padding: 6px 16px;
    height: 5vh;
    margin: 0;
  }

  .menu-button.active {
    background-color: #F7F8FA;
    margin-bottom: 0;
    padding: 4px;
    width: fit-content;
    color: black;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .menu-button.active::after {
    content: '';
    position: relative;
    bottom: 0; /* Ajusta a posição para aparecer abaixo do botão */
    left: 0;
    width: 100%; /* Largura total do botão */
    height: 0.4vh; /* Altura do retângulo */
    background-color: #FD6B3F; /* Cor laranja */
    z-index: 1; /* Coloca o retângulo acima de outros elementos */
  }

  .menu-button.active:hover::after{
    content: '';
    position: relative;
    bottom: 0; /* Ajusta a posição para aparecer abaixo do botão */
    left: 0;
    width: 100%; /* Largura total do botão */
    height: 0.4vh; /* Altura do retângulo */
    background-color: #FD6B3F; /* Cor laranja */
    z-index: 1;
  }

  .menu-button:hover{
    background-color: transparent;
  }

  .menu-button-content {
    width: fit-content;
    justify-content: center;
  }

  .sidebar-text{
    margin-left: 0;
  }

  .sidebar-text h3 {
    padding: 0;
    margin: 0;
    margin-bottom: 1px;
    color: black;
    width: max-content;
    font-size: small;
    font-weight: normal;
  }
  .sidebar-text p {
    display: none;
  }

  .icon-circle {
    display: none;
  }

  .menu-icon {
    display: none;
  }
}