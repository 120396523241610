/* ModulesAdmin.css */
.modules-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    position: relative;
}

.module-card {
    flex: 1 1 calc(20% - 20px);
    max-width: calc(20% - 20px);
    height: 3rem;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-content: center;
}

.module-card.active {
    background-color: #d4edda;
    color: #333;
}

.module-card.inactive {
    background-color: #f8d7da;
    color: #333;
}


.modal-content-modules {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    min-width: 36vw;
    max-width: 40vw;
    max-height: 75vh;
}

.modal-content-modules h2 {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    color: #0067FF;
}

.modal-content-modules label {
    width: 100%;
    margin-bottom: 10px;
    font-weight: 400;
}

.modal-content-modules p {
    width: 26%;
    margin: 0px;
}

.input-modules {
    width: 100%;
    padding: 4px;
    border: 1.5px solid #D9D9D9;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
}


.save-buttom-modules {
    margin-right: 4px;
}

.status-dropdown {
    width: 100%;
    padding: 4px;
    border: 1.5px solid #D9D9D9;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    cursor: pointer;
}

.modal-actions-modules {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.modal-actions-modules button {
    width: 8rem;
    padding: 5px 15px;
    margin-left: 0px;
    margin: 0px 10px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
}

.deleteModuleButton {
    background-color: #D9534F;
    color: white;
}

.modal-actions-modules button:last-child {
    background-color: #0067FF;
    color: white;
}

/* Define o tamanho do contêiner do menu suspenso */
.subsidiary-select-dropdown {
    width: 100%;
    margin-top: 8px;
  }
  
  /* Ajusta o menu suspenso (caixa que exibe as opções) */
  .subsidiary-select-dropdown .css-26l3qy-menu {
    max-height: 50px; /* Defina o tamanho máximo desejado */
    overflow-y: auto;
  }
  
  /* Ajusta os itens da lista no menu suspenso */
  .subsidiary-select-dropdown .css-4ljt47-MenuList {
    max-height: 50px; /* Altura máxima para os itens no menu */
    overflow-y: auto;
  }
  
  /* Ajusta o padding dentro do contêiner principal */
  .subsidiary-select-dropdown .css-1hwfws3 {
    padding: 6px;
  }
  
  /* Remove o separador entre os ícones */
  .subsidiary-select-dropdown .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .subsidiary-select-dropdown .css-1nmdiq5-menu {
    max-height: 25vh; /* Altura máxima para os itens no menu */
    overflow-y: auto;
  }

  .email-readonly {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #333;
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: not-allowed;
    box-sizing: border-box;
  }

  .css-26l3qy-menu {
    max-height: 50px !important;
    overflow-y: auto !important;
  }
  
  .css-4ljt47-MenuList {
    max-height: 50px !important;
    overflow-y: auto !important;
  }
  

  /* Responsividade para Smartphones */
@media (max-width: 768px) {
  .modules-container {
      gap: 10px;
      padding: 10px;
  }

  .module-card {
      flex: 1 1 calc(50% - 10px); /* 2 cards por linha */
      max-width: calc(50% - 10px);
      height: 2.5rem; /* Reduz altura */
      font-size: 14px; /* Reduz tamanho da fonte */
      padding: 10px;
  }

  .modal-content-modules {
      min-width: 80vw;
      max-width: 90vw;
      padding: 15px;
  }

  .modal-actions-modules button {
      width: 6rem; /* Reduz o tamanho dos botões */
      padding: 5px 10px;
      font-size: 14px;
  }

  .input-modules,
  .status-dropdown {
      font-size: 14px;
      padding: 4px;
  }

  .email-readonly {
      font-size: 14px;
      padding: 8px;
  }
}
