/* Success.css */
.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #e6f7e6; /* Fundo em tom verde claro para indicar sucesso */
    font-family: 'Arial', sans-serif;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.success-image {
    max-width: 200px;
    width: 100%;
    margin-bottom: 20px;
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.success-title {
    font-size: 2.5rem;
    margin: 0;
    color: #2e7d32; /* Verde escuro */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.success-message {
    font-size: 1.25rem;
    color: #4caf50; /* Verde médio */
    margin: 20px 0;
    max-width: 600px;
    line-height: 1.6;
}

.login-button {
    padding: 12px 24px;
    font-size: 1rem;
    color: #fff;
    background-color: #388e3c; /* Verde para botão */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.login-button:hover {
    background-color: #2e7d32;
    transform: scale(1.05);
}

.login-button:active {
    background-color: #1b5e20;
    transform: scale(0.98);
}

@media (max-width: 600px) {
    .success-title {
        font-size: 2rem;
    }
    .success-message {
        font-size: 1rem;
    }
    .login-button {
        padding: 10px 20px;
        font-size: 0.9rem;
    }
}
