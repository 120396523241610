/* Barra de Navegação */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem; /* p-4 */
    width: auto;
    height: 8vh;

}

/* Logo */
.navbar-logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.navbar-logo {
    margin-left: 1rem;
    width: clamp(3rem, 4vw, 5.5rem); /* w-12 */
    height: clamp(3rem, 4vw, 5.5rem); /* h-12 */
}

.navbarText{
    margin-right: auto; /* Empurra a frase para a direita */
    font-size: clamp(0.8rem, 1vw, 1.5rem);
    margin-right: 1rem; /* Espaçamento entre a frase e a user-section */
  }

   
  .orange-divider {
    width: 100%; /* Ocupa 100% da largura da tela */
    height: 0.1rem; /* Defina a altura conforme necessário */
    background-color: #FD6B3F;
    ; /* Cor de fundo laranja */
  }
  
 
/* User Section */
.user-section-container {
    width: 25%;
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem; /* mr-4 */
    cursor: pointer;
    position: relative; 
  }  /* Adicionado para posicionamento do retângulo de fundo */
  .user-section {
  display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
  }
  
  .photo-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent; /* Custom Blue */
    border-radius: 50%; /* rounded-r-lg */
    width: 4.1rem;
    height: 2.5rem;
    margin-right: 1rem;
    position: relative; /* Necessário para posicionamento absoluto da elipse */
    z-index: 1;
  }
  
  .userInitialNavbar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #fff;
    z-index: 2; /* Garante que fique na frente do botão */
    margin-right: -10px; /* Ajusta a posição da roda para se sobrepor ao botão */
  }
  
  .arrowIcon {
    width: 1rem; 
    height: 1rem;
    margin-right: 0.3rem;
    position: absolute; 
    right: 0; 
    top: 50%; 
    transform: translateY(-50%); 
    z-index: 4; 
}

  
  .userEllipse {
    position: absolute;
    right: 0.1rem; /* Ajuste para posicionar a elipse à direita da seta */
    width: 2.4rem; /* Largura da elipse */
    height: 2.3rem; /* Altura da elipse */
    border-radius: 50%; /* Forma de elipse */
    z-index: 1; /* Fica atrás da seta */
  }
  
  .userRectangle {
    position: absolute;
    left: 2.2rem; /* Para centralizar horizontalmente */
    transform: translateX(-50%); /* Para garantir que o retângulo esteja centralizado */
    width: 1.2rem; /* Largura do retângulo */
    height: 2.3rem; /* Altura do retângulo */
    z-index: 1; /* Fica atrás da foto e seta */
  }


/*Dropdown Menu*/
.dropdownDivider {
  height: 0.4px;
}

.dropdownMenu-navbar {
    margin: 0;
    position: absolute;
    top: 2.5rem;
    right: 0.1rem;
    width: 9.8rem;
    padding: 15px;
    border-radius: 18px;
    border: none;
    box-shadow: none; /* Remove a sombra */
    border: none; /* Adiciona uma borda */
    z-index: 3;
  }
  
  .dropdownItemNavbar {
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: end;
    z-index: 2;
  }

  
  .background-rectangle-navbar {
    position: absolute;
    top: 1rem;
    left: 1.2rem;
    width: 45px; 
    height: 40px;
    background-color: white; /* Cor do retângulo de fundo */
    z-index: 0; 
    border-radius: 4px;
  }
  

/* Responsividade para telas menores ou iguais a 500px (smartphones pequenos) */
@media (max-width: 720px) {
  .main-container-navbar {
    width: 100%;
    position: fixed; /* Fixa a barra na tela */
    top: 0; /* Fixa no topo */
    left: 0;
    z-index: 1000;
  }
  .navbar {
    height: 6vh; 
  }

  .navbar-logo {
    width: clamp(2rem, 6vw, 3rem); 
    height: clamp(2rem, 6vw, 3rem);
  }

  .user-section-container {
    width: auto; 
    flex-direction: column;
    align-items: flex-start; 
  }

  .navbarText {
    font-size: clamp(0.6rem, 1vw, 1rem);
    margin-right: 0.2rem;
  }

  .photo-menu {
    width: 4.2rem; 
    height: 1.5rem;
  }

  .dropdownMenu-navbar {
    width: 190%; 
    top: 2.2rem;
  }

  .dropdownItemNavbar {
    font-size: 0.8rem; /* Ajusta o tamanho da fonte */
  }

  .background-rectangle-navbar {
    left: 1.3rem;
  }

}
