.main-home {
  width: 100%;
  display: flex; /* Ativa o flexbox para a centralização */
  justify-content: center; 
  align-items: center;
  flex-direction: column;
  height: 91vh;
}

.main-container-home {
  padding: 0.8rem;
  width: 80%;  
  height: 100%;
  text-align: left; /* Alinha os textos à esquerda */
  display: flex;
  flex-direction: column; /* Ativa o flexbox para centralização interna */
  align-items: center;
  justify-content: center; /* Centraliza verticalmente o conteúdo dentro de .main-container-home */

}
.text-container-homepage {
  padding-left: 1.6rem;
  display: flex;
  flex-direction: column; /* Adiciona flexbox para centralização */
  align-items: flex-start; /* Alinha os textos à esquerda */
  justify-content: flex-start;
  width: 100%;
}
.text1 {
  font-weight: 600; 
  margin: 0 0 0 0;
  font-size: clamp(1.5rem, 3vw, 2rem); 
}

.text2 {
  font-weight: 600;         
  color: #0067FF;           
  margin-bottom: 1.7rem;
  margin-top: 0.1rem;
  font-size: clamp(1.5rem, 3vw, 2rem);  
}

.carouselContainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 10px 0;
}

.carouselOuter {
  overflow: hidden;
  width: 100%;
}

.carouselInner {
  padding: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Para Firefox */
}

.carouselInner::-webkit-scrollbar {
  display: none; /* Para Chrome, Safari e Opera */
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: clamp(1.5rem, 3vw, 2rem);;
}

.gridItem {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
}

.moduleEllipse {
  display: flex; /* Flexbox para centralizar o ícone dentro da elipse */
  justify-content: center; /* Centraliza o ícone horizontalmente */
  align-items: center; /* Centraliza o ícone verticalmente */
  width: clamp(5rem, 12vh, 8rem); /* Largura da elipse */
  height: clamp(5rem, 12vh, 8rem); /* Altura da elipse */
  border-radius: 50%; /* Forma de elipse */
  position: relative; /* Importante para posicionar dentro do cartão */
  margin-bottom: 0.8rem; /* Espaço entre a elipse e o texto */
}

.icon-container {
  display: flex;
  justify-content: flex-start; /* Alinha a elipse à esquerda */
  align-items: flex-start; /* Alinha a elipse no topo */
  position: relative;
}

.icon-style {
  font-size: clamp(2rem, 4rem, 6.4rem); 
  color: white; 
  z-index: 2; 
}

.homepage-card, .homepage-card.active {
  background-color: white;
  cursor: pointer;
  color: black;
  width: clamp(8rem, 13vw, 18rem);
  height: clamp(6rem, 12vw, 16rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  text-decoration: none;
  font-weight: bold;
  overflow: hidden;
  padding: 1.2rem; /* Espaçamento interno para o conteúdo do cartão */
}

.homepage-card:hover {
  transform: translateY(-5px);
}

.homepage-card.inactive {
  cursor: pointer;
  opacity: 0.4;  /* Reduz a opacidade para 50%, tornando o card visualmente "desativado" */
}

.text-style-card-homepage {
  font-size: clamp(1rem, 1rem, 3rem);
  color: black;
  overflow-wrap: break-word;
  white-space: normal; /* Permite quebras de linha */
}

.description-homepage {
  font-size: clamp(0.75rem, 0.75rem, 2rem);
  color: #5C5C5C;
  font-weight: lighter;
  margin-top: 4px;
}
.orange-footer-homepage {
  width: 100%; /* Ocupa 100% da largura da tela */
  height: 1vh; /* Defina a altura conforme necessário */
  background-color: #FD6B3F;
  ; /* Cor de fundo laranja */
}
.module-icon {
  filter: brightness(0) saturate(100%) invert(1); /* Apenas uma simulação */
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


/* Responsividade para telas menores ou iguais a 500px (smartphones pequenos) */
@media (max-width: 720px) {
  .mainpage {
    height: 100vh;
    padding-top: 7.5vh;
  }

  .main-home {
    height: auto;
    width: 100%;
  }

  .main-container-home {
    width: 100%; 
    height: auto;
    padding-top: 2.4rem;
    padding-bottom: 3rem;
  }

  .text-container-homepage {
    align-items: center; /* Centraliza o texto */
    text-align: center; /* Alinha o texto ao centro */
    padding: 0;
  }

  .text1, .text2 {
    font-size: clamp(1rem, 5vw, 1.2rem); /* Reduz ainda mais o tamanho da fonte */
  }

  .homepage-card {
    width: 85%; /* Ocupa toda a largura */
    height: 75%;
    min-width: 85%;
    min-height: 75%;
  }

  .homepage-card.active {
    width: 85%; /* Ocupa toda a largura */
    height: 75%;
    min-width: 85%;
    min-height: 75%;
  }

  .homepage-card.inactive {
    width: 85%; /* Ocupa toda a largura */
    height: 75%;
    min-width: 85%;
    min-height: 75%;
  }

  .gridContainer {
    grid-template-columns: 1fr;
    gap: 0.8rem;
  }

  .carouselContainer {
    display: none; /* Esconde o carrossel para telas muito pequenas */
  }
  .carouselOuter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .orange-footer-homepage {
    position: fixed; 
    bottom: 0; 
    left: 0; 
    width: 100%;
    height: 1vh; 
  }
}
