/* Estilo para cada usuário */
.user-card-moduleCard {
    cursor: default;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    padding-left: 2rem;
    background-color: #FFFFFF; /* Light background */
    margin: 0px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    max-width: 89%;
  }


  /* Zebra striping effect */
  .user-card-moduleCard:nth-child(even) {
    background-color: #F7F8FA; /* Slightly darker for even rows */
  }
  
  /* Hover effect for the user card */
  .user-card-moduleCard:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    transform: translateY(-1px);
  }
  

@media (max-width: 720px) {
    .user-card-moduleCard {
      padding: 2px;
      max-width: 100%;
      font-size: small;
    }
}