.root {
  margin: 60px;
  font-size: 40px;
}

.button {
  font-size: 40px;
}

/* Estilos globais para remover scroll horizontal */
body {
  overflow-y: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  background-color: #F7F8FA;
}

html, body {
  width: 100%;
  height: 100%;
}
