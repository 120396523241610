
/* Barra de pesquisa e upload */
.search-upload-bar {
    width: 82%;
    justify-content: space-between;
    display: flex;
    margin: 0 40px 20px 70px;
}

.search-input-wrapper {
    position: relative;
    width: 100%;
    margin-top: 1rem;
}

.search-input-wrapper input[type="text"] {
    width: 50%;
    padding: 10px 10px 10px 50px; /* Espaço extra para o ícone */
    border: 1px solid #ddd;
    border-radius: 15px;
}

.search-icon-search-bar {
    position: absolute;
    top: 50%;
    left: 28px;
    transform: translateY(-50%);
    font-size: 14px;
    color: #888;
}

.search-upload-bar .upload-button {
    background-color: #3B9EDC;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.search-upload-bar .upload-button:hover {
    background-color: #2A7BBF;
}

@media (max-width: 720px) {
    .search-upload-bar {
        margin: 0 14px 20px 14px;
    }

    .search-upload-bar .upload-button {
        padding: 8px 16px;
    }

    .search-input-wrapper input[type="text"] {
        width: 80%;
    }
    
}