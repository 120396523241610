/* Unauthorized.css */
.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  .error-image {
    max-width: 200px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .error-title {
    font-size: 2.5rem;
    margin: 0;
    color: #333;
  }
  
  .error-message {
    font-size: 1.25rem;
    color: #666;
    margin: 20px 0;
    max-width: 600px;
    line-height: 1.6;
  }
  
  .home-button {
    padding: 12px 24px;
    font-size: 1rem;
    color: #fff;
    background-color: #0066ff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .home-button:hover {
    background-color: #0052cc;
  }
  
  