/* UserManagement.css */
.main-container-user-management {
  height: 100%;
  overflow: hidden;
}
.user-management-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    height: 86%;
    overflow-y: hidden;
    position: relative;
    z-index: 0;
    overflow: hidden;
  }
  
  .user-management-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
    text-align: center;
  }

  .table-container {
    flex-grow: 1; /* Faz com que a tabela cresça e ocupe o espaço disponível */
    overflow-y: auto;
    padding-bottom: 20px;
    height: 80%;
  }

  .user-table {
    width: 100%;
    height: 70%;
    border-collapse: collapse; /* Remove espaço entre bordas */
  }
  
  .user-table thead {
    position: sticky;
    top: 0; /* O cabeçalho ficará no topo */
    background-color: white; /* Defina uma cor de fundo para o cabeçalho */
    z-index: 5; /* Garanta que o cabeçalho fique sobre o corpo */
  }
  /* Definir altura e estilo das células do cabeçalho */
.user-table thead th {
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 2px solid #ddd;
  text-align: center;
}
  
.user-table tbody td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

  .user-table th, .user-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
    width: auto;
    overflow: hidden;
  }
  .user-table td {
    height: 4vh;
    vertical-align: middle;
  }
  
  .user-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .user-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .user-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .toggle-button {
    padding: 0.4rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .toggle-button.enable {
    background-color: #28a745;
    width: 5rem;
    color: #fff;
  }
  
  .toggle-button.disable {
    background-color: #dc3545;
    width: 5rem;
    color: #fff;
  }
  
  .toggle-button:hover {
    opacity: 0.9;
  }
  .search-upload-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem ;
}

/* Search Bar ocupando 60% da largura */
.search-bar {
    display: flex;
    align-items: center;
    position: relative;
    width: 60%;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.search-input {
    width: 100%;
    padding: 10px 10px 10px 35px;
    border: none;
    border-radius: 5px 0 0 5px;
}

.search-icon {
    position: absolute;
    left: 10px;
    font-size: 16px;
    color: #888;
}

.filter-button {
    background-color: #0067FF;
    color: white;
    border: none;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
}

.filter-button:hover {
    background-color: #2A7BBF;
}

/* Botão de upload */
.upload-button {
    background-color: #0067FF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.upload-button:hover {
  background-color: #0251c6;
  transform: translateY(-2px);
}

/* Estilo para as bandeiras */
.flag-icon {
  width: 24px;
  height: 16px;
  margin-right: 10px;
  vertical-align: middle;
}






.edit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
}

.edit-button:hover {
  background-color: #0056b3;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0.125rem);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popup-content h3 {
  margin-top: 0;
}

.save-button {
  margin-right: 0px;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  margin-top: 20px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3B9EDC;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-screen p {
  margin-left: 1rem;
  font-size: 1.2rem;
  color: #333;
}

.modal-content-user-management {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 35vw;
  max-height: 70vh;
}

.modal-content-user-management h2 {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  color: #0067FF;
}

.popup-label {
  width: 18%;
  margin: 0px;
  margin-right: 8px;
}

.pop-text {
  font-weight: bold;
}

.textos-edit-popup {
  display: flex;
  align-items: center;
  font-weight: 400;
  height: 2.5rem;
}

.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
}

.toggle-button svg {
  width: 16px;
  height: 16px;
  color: #007bff; /* Cor azul padrão, ajuste conforme necessário */
}

