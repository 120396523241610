/* AdminPage.css */
/* Estrutura geral e estilos do layout */
.admin-container {
    display: flex;
    height: 100vh;
    background-color: #F0F8FF;
    flex-direction: column;
}
.admin-main {
    display: flex;
    width: 100%;
    height: 89vh;
}
.orange-divider-adminpage {
    width: 100%;
    height: 1vh;
    background-color: #FD6B3F;
    position: fixed; 
    bottom: 0;
    left: 0; 
    z-index: 10;
}

.content {
    width: 80vh;
    flex-grow: 1;
    padding: 0px;
    background-color: #F7F8FA;
    overflow-y: auto;
    overflow-x: hidden;
}


/* Estilos para a seção de administração de módulos */
.modules-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    position: relative;
}

.module-card {
    flex: 1 1 calc(20% - 20px);
    max-width: calc(20% - 20px);
    height: 3rem;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-content: center;
}

.module-card.active {
    background-color: #d4edda;
    color: #333;
}

.module-card.inactive {
    background-color: #f8d7da;
    color: #333;
}

.module-card h3 {
    margin: 0;
    font-size: 18px;
}

.module-card:hover {
    transform: translateY(-5px);
}

/* Ajuste para o botão de adicionar módulo */
.add-module-button {
    background-color: #0067FF;
    color: white;
    border: none;
    margin-right: 24px;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    bottom: 20px;
    right: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.add-module-button:hover {
    background-color: #0251c6;
    transform: translateY(-2px);
}

.remove-user-button {
    background-color: #D9534F;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

/* customAlert.css */
.custom-popup {
    background-color: #f8f9fa; /* Cor de fundo */
    border-radius: 8px;
    color: #333;
  }
  
  .custom-title {
    color: #d9534f; /* Cor do título */
    font-weight: bold;
  }
  
  .custom-confirm-button {
    background-color: #d9534f;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
  }
  
  .custom-cancel-button {
    background-color: #8a8b8c;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
  }
  

  .modal .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  
  .modal .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #0067FF;
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  @media (max-width: 720px) {
    .admin-main {
        padding-top: 7.5vh;
        flex-direction: column;
    }

    .content {
        width: 100%;
        overflow-x: hidden;
        height: 100%;
    }
  }