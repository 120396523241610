/* Estilos para o cartão do módulo */
/* Estilos para os grupos de usuários dentro dos módulos */
.module-group-card {
  background-color: white;
  height: 18rem;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0px;
  text-align: center;
  cursor: default;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
  flex: 0 1 calc(50% - 20px); /* Até 2 cards por linha */
  box-sizing: border-box; /* Para garantir que padding e borda sejam incluídos na largura total */
}
.module-group-header {
  cursor: default;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 12%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background-color: #00284D;
}

.module-title {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
  margin: 0 25px;
  text-align: center;
  color: white;
}
.title-module-card {
  background-color: #0067FF;  
  width: auto;
  border-radius: 15px 0px 50px 0px;
  height: 100%;

  /* Flexbox to center content */
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
}

.icons-module-card {
  display: flex;
  align-items: center;
  width: auto;
  margin-right: 10px;
}
.icon-container-user-adminpage {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.module-ellipse-homepage-user-adminpage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
}

.icon-style-user-adminpage {
  font-size: 24px;
  color: white;
}


.user-count {
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
}

.user-count-icon {
  margin-right: 4px;
  margin-left: 4px;
  font-size: 20px;
  color: white; /* Cor do ícone de usuários */
}

.divider-user-adminpage {
  width: 100%; /* Ocupa toda a largura disponível */
  height: 1px; /* Espessura do divisor */
  background-color: #bcbcbc; /* Cor do divisor */
  margin: 20px 0; /* Espaçamento acima e abaixo do divisor */
}
/* Estilos para a lista de usuários */
.module-group-users {
  height: 12rem; /* Altura fixa para forçar rolagem quando necessário */
  overflow-y: auto; /* Permitir rolagem vertical */
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;
  margin-top: 10px;
}

.module-group-users::-webkit-scrollbar {
  width: 6px;
}

.module-group-users::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 2px;
}

.user-card span {
  font-size: 15px;
  font-weight: 500;
  color: #333;
}

/* Estilo para o botão de remover usuário */
.remove-user-button {
  background-color: transparent;
  color: #D9534F;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.3s ease, transform 0.3s ease;
}

.remove-user-button:hover {
  color: #c9302c;
  transform: scale(1.1);
}
/* Estilos para as ações do módulo */
.module-group-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.add-user-button,
.bulk-upload-button {
  border: none;
  cursor: pointer;
  margin-left: 8px;
  display: flex;
  align-items: center;
  background-color: transparent;
}

.remove-user-button-modulecard {
  border: none;
  cursor: pointer;
  margin-left: 8px;
  display: flex;
  align-items: center;
  background-color: transparent; 
  color: #ddd;
}

/* Estilo de fundo geral e ajuste dos módulos */
.clean-layout {
  background-color: #f0f9ff;
  padding: 20px;
  border-radius: 10px;
}

.remove-user-button-usercard {
  background-color: transparent;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.3s ease, transform 0.3s ease;
}


.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  margin-top: 20px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #0067FF;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-screen p {
  margin-left: 1rem;
  font-size: 1.2rem;
  color: #333;
}


@media (max-width: 720px) {
  .title-module-card {

  }
  .module-group-users {
    height: 10rem;
  }
}