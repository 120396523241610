
/* Estilos para os modais */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(0.125rem);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 90vw; /* Ajuste para manter responsivo */
    max-width: 30rem; /* Limita a largura máxima */
    height: auto;
    max-height: 80vh; /* Limita a altura máxima */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden; /* Garante que o conteúdo não ultrapasse os limites */
}
.modal-content h2 {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    color: #0067FF;
}

/* Barra de pesquisa */
.search-bar-modal {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .search-input-modal {
    width: 100%;
    padding: 10px 10px 10px 35px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
  }
  
  .search-icon-modal {
    position: absolute;
    left: 10px;
    font-size: 20px;
    color: #888;
  }

.modal-content label {
    display: block;
    margin-bottom: 10px;
}
/* Estilo para o checkbox de selecionar todos */
.select-all-container {
    margin-bottom: 10px;
    display: flex;
    align-items: center;    
    font-weight: bolder;
  }

.modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.modal-actions button {
    width: 5rem;
    padding: 5px 15px;
    margin-left: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
}

.modal-actions button:first-child {
    background-color: transparent;
    border: 1.5px solid #D9D9D9;
    color: #535353DD;
}

.modal-actions button:last-child {
    background-color: #0067FF;
    color: white;
}

/* Adicionando rolagem à lista de seleção de usuários */

.user-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-size: 16px; /* Define o tamanho do texto */
}

.user-list-container {
    height: clamp(25%, 60%, 80%);
    overflow-y: auto;
    padding-right: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
.user-card span {
    font-size: 16px;
}

input[type="checkbox"] {
    appearance: none; /* Remove o estilo padrão do navegador */
    width: 16px; /* Largura fixa */
    height: 16px; /* Altura fixa */
    background-color: transparent;
    border: 1.5px solid #D9D9D9;
    border-radius: 1px;
    cursor: pointer;
    display: inline-block; /* Garantir que o checkbox se comporte como um elemento inline */
    vertical-align: middle; /* Mantém o alinhamento vertical com o texto */
    margin-right: 10px;
}

input[type="checkbox"]:checked {
    background-color: #0067FF; /* Cor quando marcado */
    border-color: #0067FF;
}

input[type="checkbox"]:checked::before {
    content: '✔'; 
    display: block;
    color: white;
    text-align: center;
    line-height: 16px; /* Alinhamento vertical do ícone */
    font-size: 12px; /* Ajusta o tamanho do ícone */
}

label {
    display: flex;
    align-items: center;
    gap: 10px; /* Espaço entre o checkbox e o texto */
}

@media (max-width: 720px) {
    .modal-content {
        padding: 10px;
        height: 65vh;
        margin: 1.2rem;
    }
    .modal-content h2 {
        font-size: 1.2rem;
        margin-bottom: 10px;
    }
    .select-all-container {
        margin-bottom: 4px;
    }
    .user-list-container {
        display: flex;    
        flex-direction: column;
        align-items: flex-start;
        font-size: small;
    }

    .modal-content label {
        font-size: small;
    }

}