/* Loading.css */
.loading-container-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
    font-family: 'Arial', sans-serif;
  }
  
  .spinner-login {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Cor do spinner */
    border-left-color: #0066ff; /* Cor do spinner animado */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; /* Animação */
    margin-bottom: 15px;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg); /* Rotacionar continuamente */
    }
  }
  
  .loading-text-login {
    font-size: 1.2rem;
    color: #333;
  }
  