

/* Responsividade web */
.login-container {
    display: flex;
    height: 100vh;
    overflow: hidden;
}


.image-carousel {
    width: 60%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-carousel::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 133, 255, 0.5); /* Máscara azul com 50% de transparência */
    z-index: 2; /* Eleva a máscara para frente das imagens */
}

.image-carousel img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: relative;
    z-index: 1; /* Garante que as imagens fiquem atrás da máscara */
}

.image-carousel .carousel-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px; /* Define o tamanho da logo */
    height: auto; /* Mantém a proporção da logo */
    z-index: 3; /* Fica na frente da máscara */
}


/* Estilo para o contêiner da imagem e do autor */
.slide-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel-image {
    width: 100%;
    height: 100vh; /* Ajuste para a altura da tela */
    object-fit: cover;
  }
  
  /* Estilo para o nome do autor */
  .image-author {
    position: absolute;
    bottom: 0;
    right: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 8px 8px;
    font-size: 0.75rem;
    z-index: 3; /* Garante que o texto esteja à frente da imagem */
  }
  

/*sibe B*/
/* Formulário */
.sideb-container {
    width: 40%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Allows us to position the orange divider at the bottom */
}

.sideb {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative; /* For orange divider to be positioned relative to this */
}

.orange-divider-login {
    width: 100%;
    height: 0.4rem;
    background-color: #FD6B3F;
    position: fixed; /* Ensures the divider stays at the bottom of the viewport */
    bottom: 0;
    left: 0;
}

.form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Ensures vertical arrangement */
}

.centered-container {
    width: 66%;
    height: 80vh;
    overflow: hidden;
    align-content: center;
}
/* Títulos e Logos */
.logo-login {
    width: clamp(10rem, 13vw, 15rem);
    margin-bottom: clamp(1rem, 2vw, 3rem);
}

.welcome-title {
    color: #000000; /* Gray-800 */
    font-size: clamp(1rem, 2vw, 2rem);
    font-weight: 600;
    margin: 0 0 clamp(1rem, 2vw, 3rem) 0;
}

.forms {
    width: 100%;
}

.forms {
    width: 100%;
}

/* Contêiner para input e ícone */
.input-container {
    position: relative; /* Posiciona o contêiner como referência para o ícone */
    width: 100%;
    margin-bottom: 16px; /* Espaçamento entre os campos de entrada */
}

/* Campo de input */

.input-field {
    width: 100%;
    height: clamp(2.5rem, 2.5vw, 3.5rem);
    box-sizing: border-box; /* Garante que padding e borda sejam incluídos na largura total */
    padding: 8px 12px;
    padding-left: 40px;
    color: #718096;
    background-color: transparent;
    border: 1px solid #CBD5E0;
    border-radius: 2%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border 0.3s;
}


.input-field:focus {
    border-color: #5A67D8; 
}

/* Ícone dentro do input */
.input-icon {
    position: absolute;
    left: 12px; /* Ajusta conforme necessário para alinhar o ícone */
    top: 50%;
    transform: translateY(-50%); /* Centraliza verticalmente o ícone */
    width: 20px; /* Largura do ícone */
    height: 20px; /* Altura do ícone */
    pointer-events: none; /* Ícone não interfere nas interações do input */
}

/* form-footer */
.form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
    margin: 0;
}

/* Botões */
.primary-button {
    width: 100%;
    padding: 12px 16px;
    color: white;
    font-weight: 500;
    border-radius: 2px;
    background-color: #0067FF; /* Custom Blue */
    transition: background-color 0.3s, box-shadow 0.3s;
    border: none;
}

.primary-button:hover {
    background-color: #093ba9; /* Indigo-500 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.primary-button:disabled {
    background-color: #E2E8F0; /* Gray-300 */
    cursor: not-allowed;
}

.google-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 10px;
    border: 1px solid #808080; /* Gray-300 */
    border-radius: 2px;
    font-weight: 500;
    transition: background-color 0.3s;
    background-color: transparent;
}

.google-button:hover {
    background-color: #e0e2e2; /* Gray-100 */
}

.google-button:disabled {
    cursor: not-allowed;
}

.separator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0.5rem 0;
}

.separator-line {
    flex-grow: 1;
    height: 1px;
    background-color: #E2E8F0; /* Gray-300 */
}

.separator-text {
    margin: 0 8px;
    font-size: 0.8rem; /* Text-sm */
}

.checkbox-container {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
}

.checkbox-container input {
    margin-right: 8px;
}

.forgot-password-link {
    color: #0085FF; /* Link Blue */
    text-decoration: none;
    font-size: 0.8rem; /* Text-sm */
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.outlook-icon {
    width: 24px;
    height: 24px;
}



.error-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3); /* Fundo semi-transparente mais suave */
    z-index: 1000; /* Ficar em cima de tudo */
  }
  
  .error-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Sombra mais suave */
    max-width: 400px;
    width: 90%;
    text-align: center;
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 1;
  }
  
  .error-text {
    color: #333;
    font-size: 16px;
    margin-bottom: 20px;
    display: block;
  }
  
  .close-button-login {
    background-color: #0067FF;
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .close-button-login:focus {
    outline: none;
  }

  /* Responsividade mobile */
@media (max-width: 720px) {
    .image-carousel {
        display: none; 
    }

    .login-container {
        flex-direction: column; 
        width: 100%;
    }

    .sideb-container {
        width: 100%;
    }
}