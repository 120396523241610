/* Welcome.css */
.welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f4ff; /* Light blue background for a welcoming feel */
    font-family: 'Arial', sans-serif;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.welcome-image {
    max-width: 200px;
    width: 100%;
    margin-bottom: 20px;
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.welcome-user-title {
    font-size: 2.5rem;
    margin: 0;
    color: #1a73e8; /* Blue color for title */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.welcome-message {
    font-size: 1.25rem;
    color: #4a4a4a; /* Neutral gray for readability */
    margin: 20px 0;
    max-width: 600px;
    line-height: 1.6;
}

.login-button {
    padding: 12px 24px;
    font-size: 1rem;
    color: #fff;
    background-color: #1a73e8; /* Blue button color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.login-button:hover {
    background-color: #155ab3;
    transform: scale(1.05);
}

.login-button:active {
    background-color: #0d3d87;
    transform: scale(0.98);
}

@media (max-width: 600px) {
    .welcome-title {
        font-size: 1.5rem;
    }
    .welcome-message {
        font-size: 1rem;
    }
    .login-button {
        padding: 10px 20px;
        font-size: 0.9rem;
    }
}
