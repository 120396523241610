h2 {
    color: #333;
    margin-bottom: 20px;
    padding-left: 20px;
}
.modules-users-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 30px;
    padding: 20px 50px 60px 70px;  
}

@media (max-width: 720px) {
    .modules-users-container {
        padding: 14px;
        display: flex;
        flex-direction: column;
    }
}
